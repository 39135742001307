<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        <span class="vg_ml_8" style="color: red">如需获取最新数据请刷新</span>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`appr_inst_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :need-fixed-height="true"
        @getTableData="getDequsNow"
      >
        <template v-slot:appr_inst_next_node="scope">
          <div style="text-align: center">
            <el-tag :type="getInstNextNode(scope.row.appr_inst_next_node).type" size="mini">
              {{ getInstNextNode(scope.row.appr_inst_next_node).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import SearchTable from '@/components/table/SearchTableV2.vue';
import { get } from '@api/request';
import { apprAPI } from '@api/modules/appr';
import { cloneDeep, debounce } from 'lodash';
import { getDate } from '@assets/js/dateUtils';

export default {
  name: 'boardAllLeft',
  components: { SearchTable },
  data() {
    return {
      loadFlag: true,
      tableData: [],
      totalPage: 0,
      tableProperties: [
        this.$store.state.index,
        { prop: 'appr_inst_info', label: '审批说明', itemType: 'input', input: true, labelWidth: 500, align: 'left' },
        {
          prop: 'appr_inst_next_node',
          label: '审批状态',
          itemType: 'select',
          options: [
            {
              value: -1,
              label: '草拟（拒绝）'
            },
            {
              value: 1,
              label: '在批'
            },
            {
              value: 0,
              label: '生效'
            }
          ],
          input: true,
          widthAuto: true
        },
        {
          prop: 'update_time',
          label: '审批时间',
          itemType: 'date',
          input: true,
          widthAuto: true,
          sortable: true,
          valueFormat: 'timestamp',
          formatter: val => getDate(val, true)
        },
        { prop: 'stff_name', label: '审批人', itemType: 'input', input: true, widthAuto: true },
        { prop: 'appr_inst_remark', label: '原因（源自审批流备注）', itemType: 'input', input: false, widthAuto: true }
      ]
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getDequsNow();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.getRightList();
    }, 1000),
    //获取审批记录
    getRightList() {
      let searchForm = cloneDeep(this.$refs.multiTable.searchForm);
      searchForm.update_time = searchForm.update_time ? searchForm.update_time / 1000 : null;
      get(apprAPI.getApproveV1, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            setTimeout(() => {
              this.loadFlag = false;
            }, 300);
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
          } else if (res.data.code === 3) {
            console.log('未登录');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.loadFlag = true;
      this.initData();
    },
    getInstNextNode(val) {
      if (val === 0) {
        //生效
        return { name: '生效', type: 'success' };
      } else if (val === -1) {
        //草拟拒绝
        return { name: '草拟（拒绝）', type: 'danger' };
      } else {
        //在批
        return { name: '在批', type: 'warning' };
      }
    }
  }
};
</script>

<style scoped></style>
